.App {
  text-align: center;  

}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

tr:hover{
  background:#dedede  !important;
}
/* page title  */

.corners {
  width: 450px;
  height: 120px;
  margin: 30px auto;  
  position: relative;
  padding-top: 25px;
  
}

.top, .bottom {
  position: absolute;
  width: 20px;
  height: 20px;
}

.top {
  top: 30px;
  border-top: 4px solid;
}

.bottom {
  bottom: 0;
  border-bottom: 4px solid;
}

.left {
  left: 0;
  border-left: 4px solid;
}

.right {
  right: 0;
  border-right: 4px solid;
}

.swal2-container {
  z-index: 11000 !important;
}