@import url(https://fonts.googleapis.com/css2?family=Bodoni+Moda:wght@700&family=Montagu+Slab:wght@500&family=Original+Surfer&display=swap);
body {
  
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;  

}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

tr:hover{
  background:#dedede  !important;
}
/* page title  */

.corners {
  width: 450px;
  height: 120px;
  margin: 30px auto;  
  position: relative;
  padding-top: 25px;
  
}

.top, .bottom {
  position: absolute;
  width: 20px;
  height: 20px;
}

.top {
  top: 30px;
  border-top: 4px solid;
}

.bottom {
  bottom: 0;
  border-bottom: 4px solid;
}

.left {
  left: 0;
  border-left: 4px solid;
}

.right {
  right: 0;
  border-right: 4px solid;
}

.swal2-container {
  z-index: 11000 !important;
}
@font-face {
  font-family: "Century Gothic";
  src: url(/src/assets/fonts/Century\ Gothic.ttf) format("truetype");
}

@font-face {
  font-family: "Clarendon";
  src: url(/src/assets/fonts/Clarendon\ Regular.otf) format("opentype");
}
