@import url('https://fonts.googleapis.com/css2?family=Bodoni+Moda:wght@700&family=Montagu+Slab:wght@500&family=Original+Surfer&display=swap');

@font-face {
  font-family: "Century Gothic";
  src: url(/src/assets/fonts/Century\ Gothic.ttf) format("truetype");
}

@font-face {
  font-family: "Clarendon";
  src: url(/src/assets/fonts/Clarendon\ Regular.otf) format("opentype");
}